import * as fabric from 'fabric';
import type { FavoriteItem } from '@/views/favorite/typing.ts';
import {joinUrl} from "@repo/utils";
import {getAppConfig} from "@/utils";

const { URL_PARSE_URL } = getAppConfig();
const baseProxyUrl=joinUrl(URL_PARSE_URL,'proxy')

const canvas = new fabric.Canvas(undefined, {
  width: 420,
  height: 0,
  backgroundColor: 'white'
});

async function generate(properties:Record<string, string>,list: FavoriteItem[]) {
  const cardPadding = 10;
  let top = 20;

  function createText(label: string, value: string, labelLeft: number, valueLeft: number, top: number, fontSize: number = 14) {
    const labelText = new fabric.FabricText(label, {
      left: labelLeft,
      top,
      fontSize,
      fill: 'black'
    });
    const valueText = new fabric.Textbox(value, {
      left: valueLeft,
      top,
      fontSize,
      fill: 'black',
      width: 200,
      splitByGrapheme: true
    });
    return { labelText, valueText, height: valueText.height };
  }

  async function createCard(item: FavoriteItem, top: number) {
    const url=`${baseProxyUrl}?url=${item.thumb}`
    const img = await fabric.FabricImage.fromURL(url, {
      crossOrigin: 'anonymous'
    });
    const scaleFactor = Math.min(95 / img.width, 95 / img.height);
    img.scale(scaleFactor);
    img.set({
      left: 30 + (95 - img.getScaledWidth()) / 2,
      top: top + 10 + (95 - img.getScaledHeight()) / 2,
    });
    canvas.add(img);

    const labels = ['名称:', '编号:', '备注:', '数量:'];
    const values = [item.name, item.id, item.memo, `${item.num}`];
    let height = 0;
    const padding = 10;

    labels.forEach((label, index) => {
      const {
        labelText,
        valueText,
        height: valueHeight
      } = createText(label, values[index] || '', 140, 190, top + height + padding);
      canvas.add(labelText);
      canvas.add(valueText);
      height += valueHeight + padding;
    });

    const rect = new fabric.Rect({
      left: 20,
      top,
      width: 390,
      height: height + padding,
      stroke: '#999',
      rx: 7,
      ry: 7,
      strokeWidth: 1,
      fill: 'transparent'
    });
    canvas.add(rect);

    return height + padding + cardPadding;
  }

  const propertyLabels = ['公司:', '联系人:', '电话:', '地址:', '备注:'];
  const propertyKeys = ['company', 'contact', 'phone', 'address', 'memo'];
  propertyLabels.forEach((label, index) => {
    const propertyKey=propertyKeys[index] as string;
    const value = properties[propertyKey];
    const { labelText, valueText, height } = createText(label, value || '', 25, 100, top);
    canvas.add(labelText);
    canvas.add(valueText);
    top += height + 10; // Add padding between rows
  });

  for (const item of list) {
    top += await createCard(item, top);
  }

// Adjust canvas height
  canvas.setDimensions({ height: top + cardPadding });
}


export async function exportImage(properties:Record<string, string>,list: FavoriteItem[]) {
  canvas.clear();
  canvas.set('backgroundColor', 'white');
  await generate(properties,list);

  const dataURL = canvas.toDataURL({
    format: 'png',
    multiplier: 1,
    quality: 1.0  // Set quality for JPEG if needed
  });

  return dataURL;
}
