import {
  createRouter,
  createWebHistory
} from 'vue-router';

import { routes } from './routes';
import { isIOS } from '@repo/utils';
import { useWx } from '@repo/wx';

const router = createRouter({
  history: createWebHistory(),
  routes
});

const { wxConfig } = useWx();

router.afterEach(async () => {
  if (!isIOS()) {
    wxConfig(location.href).then(() => {
      console.log('wxConfig success');
    });
  }
});

export { router };
