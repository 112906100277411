<script setup lang="ts">
import { ref, toRaw } from 'vue';
import {
  NModal,
  NCard,
  NForm,
  NInput,
  NFormItem,
  NSpace,
  NInputNumber,
  NButton
} from '@likui628/naive-ui';
import { saveFavorite } from '@/api';

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  id: string,
  thumb: string,
  properties: Record<string, string>[],
  memo?: string,
  num?: number,
  showForm?:boolean
}>();

const emit = defineEmits<{
  (e: 'success', payload: { id: string, memo: string, num: number }): void
}>();


const showModal = ref(false);
const model = ref<{ num: number, memo: string }>({
  num: props.num || 1,
  memo: props.memo || ''
});

const onFavorite = async () => {
  const { id, thumb } = props;
  const { memo, num } = model.value;

  await saveFavorite({ id, thumb, properties: toRaw(props.properties), memo, num });
  showModal.value = false;
  emit('success', { id, memo, num });
};

const onClick=()=>{
  if(props.showForm){
    showModal.value=true
  }else{
    onFavorite()
  }
}
</script>

<template>
  <div v-bind="$attrs" @click="onClick">
    <slot />
  </div>
  <NModal v-model:show="showModal">
    <NCard>
      <div>
        <NForm
          ref="formRef"
          :model="model"
          label-placement="left"
          label-width="auto"
          require-mark-placement="right-hanging"
        >
          <NFormItem label="备注" path="model.meno">
            <NInput v-model:value="model.memo" type="textarea"></NInput>
          </NFormItem>
          <NFormItem label="数量" path="model.num">
            <NInputNumber v-model:value="model.num" :min="1" style="width: 100%" />
          </NFormItem>
        </NForm>
        <NSpace justify="end">
          <NButton @click="onFavorite">收藏</NButton>
        </NSpace>
      </div>
    </NCard>
  </NModal>
</template>

<style scoped>
</style>
