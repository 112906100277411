<script setup lang="ts">
</script>

<template>
  <div class="container">
    <div class="main-content">
      <RouterView />
    </div>
  </div>
</template>

<style scoped>
.container {
  background-color: #31363B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.main-content {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
