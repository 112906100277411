<script setup lang="ts">
import { computed } from 'vue';
import { QRScanModal } from '@repo/components';
import { useRouter } from 'vue-router';
import { useParamStore } from '@/store/param.ts';
import { getAppConfig } from '@/utils';
import { isWeixinBrowser, joinUrl } from '@repo/utils';
import axios from 'axios';
import { useMessage } from '@likui628/naive-ui';
import { $t } from '@repo/locales';
import { until } from '@vueuse/core';
import { useWx } from '@repo/wx';


const router = useRouter();

const cloudKnitReg = /^(?:https?:\/\/)?(?:[\w-]+\.)?cloudknit\.cn(?:\/project)?\/(?:([^/?]+)\/)?([^/?]+)$/;
const oldCloudKnitReg = /^(?:https?:\/\/)?([\w-]+)\.?cloudknit\.cn\/\?pathId=([a-zA-Z0-9]+)/;
const style3dReg = /(?:https?:\/\/)?(?:[\w-]+\.)?style3d\.com\/fabric\/(\w+)/;
const style3dShortReg = /(?:https?:\/\/)?(?:[\w-]+\.)?style3d\.com\/u\/(\w+)/;

const isWechat = isWeixinBrowser();

const validator = (text: string) => {
  return oldCloudKnitReg.test(text) || cloudKnitReg.test(text) || style3dReg.test(text) || style3dShortReg.test(text);
};

const { URL_PARSE_URL } = getAppConfig();
const { applyFabricToProject, refreshLoadResourceByRoute } = useParamStore();

const onScanSuccess = async (val: string) => {
  if (cloudKnitReg.test(val) || oldCloudKnitReg.test(val)) {
    await handleCloudKnitResource(val);
  } else if (style3dReg.test(val)) {
    await handleStyle3dResource(val);
  } else if (style3dShortReg.test(val)) {
    const match = val.match(style3dShortReg);
    if (match && match[1]) {
      try {
        const response = await axios.get<string>(joinUrl(URL_PARSE_URL, `/style3d/u/${match[1]}`));
        await handleStyle3dResource(response.data);
      } catch (error) {
        console.error('Error fetching the real URL:', error);
      }
    }
  }
};
const handleStyle3dResource = async (url: string) => {
  const match = url.match(style3dReg);
  if (match && match[1]) {
    await router.push({ name: 'style3d', params: { id: match[1] } });
    await refreshLoadResourceByRoute();
  }
};

const handleCloudKnitResource = async (val: string) => {
  let type: string = '';
  let id: string = '';
  const match = val.match(cloudKnitReg) || val.match(oldCloudKnitReg);
  if (match && match[2]) {
    type = match[1] || '';
    id = match[2];
    const name = type === 'fabric' ? 'fabric' : 'project';

    if (name === 'fabric') {
      await router.push({ name, params: { id } });
      await refreshLoadResourceByRoute();
    } else {
      await applyFabricToProject(id);
    }
  }
};

const { wxScan, wxInstance } = useWx();
const message = useMessage();

const loading = computed(() => !wxInstance.isReady.value);

const onScan = async () => {
  if (loading.value) {
    return;
  }

  try {
    await until(wxInstance.isReady).toBe(true);

    await wxScan({
      onSuccess: function(res: any) {
        const valid = validator(res.resultStr);
        if (valid) {
          onScanSuccess(res.resultStr);
        } else {
          message.warning($t('message.no-corresponding-material'));
        }
      },
      onFailure: function(err: any) {
        message.error(JSON.stringify(err));
      }
    });
  } catch (e) {
    message.error(JSON.stringify(e));
  }
};
</script>

<template>
  <div>
    <span v-if="isWechat" @click="onScan" :style="{  color: wxInstance.isReady ? '#d0d0d0' : '#6c6c6c' }">
       <slot name="scan-icon" :loading="loading"></slot>
    </span>
    <template v-else>
      <QRScanModal @change="onScanSuccess" :validator="validator">
        <slot name="scan-icon" :loading="false">
        </slot>
      </QRScanModal>
    </template>
  </div>
</template>
<style scoped>
</style>
