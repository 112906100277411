import type { Project } from './typing.ts';
import { db } from './db.ts';


export async function saveRecentProject(project: Omit<Project, 'date'>) {
  const found = await db.get('projects', project.id);
  if (found) {
    await db.put('projects', { ...project, date: found.date });
  } else {
    await db.put('projects', { ...project, date: Date.now() });
  }
}

export async function getRecentProjects(count: number = 10) {
  const data = await db.getAllFromIndex('projects', 'by-date', count, 'prev');
  return data;
}
