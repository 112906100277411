<script setup lang="ts">
import {NButton, NInput, NInputGroup, NSelect} from '@likui628/naive-ui';
import {Toolbar, FabricCard, ReturnButton} from './components';
import {computed, onMounted, ref} from 'vue';
import {deleteFavorites, type Favorite, getFavorites, searchFavorites} from '@/api';
import type {FavoriteItem} from './typing.ts';
import {useRouter} from 'vue-router';
import {dateRange} from '@repo/utils';


const favorites = ref<FavoriteItem[]>([]);

onMounted(async () => {
  await queryFavorites();
});


const date = ref(0);
const options = ref([
  {label: '今日', value: 0},
  {label: '本周', value: 1},
  {label: '本月', value: 2},
  {label: '全部', value: -1}
]);

const range = computed<[number, number] | undefined>(() => {
  switch (date.value) {
    case 0:
      return dateRange('day');
    case 1:
      return dateRange('week');
    case 2:
      return dateRange('month');
  }
  return undefined;
});

const fabricNameMapping = (item: Favorite) => {
  let name = '';
  const found = item.properties.find(item => item.name === '名称');
  if (found) {
    name = found.value || '';
  }
  return {...item, name};
};

const searchTerms = ref<string>('');
const queryFavorites = async () => {
  let items: Favorite[] = [];
  if (searchTerms.value) {
    items = await searchFavorites(searchTerms.value, 9999, range.value);
  } else {
    items = await getFavorites(9999, range.value);
  }
  favorites.value = items.map(fabricNameMapping);
};

const onSearch = async () => {
  await queryFavorites();
};

const onSelected = (item: FavoriteItem, val: boolean) => {
  item.selected = val;
};

const onSelectAll = () => {
  favorites.value.forEach((item) => {
    item.selected = true;
  });
};

const selected = computed(() => {
  return favorites.value.filter(item => item.selected);
});

const onChange = (payload: { id: string, memo: string, num: number }) => {
  const {id, memo, num} = payload;
  const found = favorites.value.find(item => item.id === id);
  if (found) {
    found.memo = memo;
    found.num = num;
  }
};

const onDelete = async () => {
  if (selected.value.length < 1) {
    return;
  }
  const ids = selected.value.map(item => item.id);
  await deleteFavorites(ids);
  await queryFavorites();
};

const router = useRouter();
const onReturn = () => {
  router.back();
};
</script>

<template>
  <div class="favorite-page">
    <div class="header">
      <ReturnButton class="return-button" @click="onReturn"/>
      <NInputGroup>
        <NSelect
            v-model:value="date"
            :options="options"
            style="width: 120px;"
            @update:value="onSearch"
        />
        <NInput type="text" v-model:value="searchTerms" placeholder="搜索(供应商,名称,编号等)" clearable/>
        <NButton type="primary" ghost @click="onSearch">搜索</NButton>
      </NInputGroup>
    </div>
    <div class="content">
      <div class="favorite-list">
        <FabricCard
            class="item"
            v-bind="item" v-for="item in favorites"
            :key="item.id"
            @selected="(val)=>onSelected(item,val)"
            @change="onChange"
        />
      </div>
    </div>
    <Toolbar
        class="toolbar"
        @select-all="onSelectAll"
        @delete="onDelete"
        :selected="selected"
        :favoriteList="favorites"
    />
  </div>
</template>

<style>
.favorite-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 35px;
  padding: 15px 10px 10px;
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.favorite-list {
  flex: 1;
  width: 100%;
  padding: 0 10px;
  overflow-y: auto;
}

.favorite-list > .item {
  margin-bottom: 10px;
}

.toolbar {
  flex: 0 0 40px;
  padding-bottom: 10px;
}

.return-button {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
