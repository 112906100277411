import {db} from './db.ts';
import type {Favorite} from './typing.ts';

function tokenize(favorite: Omit<Favorite, 'terms' | 'date'>) {
  const words = new Set<string>();
  words.add(favorite.id);
  favorite.memo && words.add(favorite.memo);
  favorite.properties.forEach((prop) => {
    prop.value && words.add(prop.value);
  });
  return Array.from(words);
}

export async function saveFavorite(favorite: Omit<Favorite, 'terms' | 'date'>) {
  await db.put('favorites', {...favorite, terms: tokenize(favorite), date: Date.now()});
}

export async function getFavorites(count: number = 10, date?: [number, number]) {
  const queryRange = date && IDBKeyRange.bound(date[0], date[1]);
  const data = await db.getAllFromIndex('favorites', 'by-date', count, 'prev', queryRange);
  return data;
}

export async function searchFavorites(keyword: string = '', count: number = 10, date?: [number, number]) {
  const queryRange = date && IDBKeyRange.bound(date[0], date[1]);
  const data = await db.searchByTerms('favorites', 'fulltext', keyword, count, queryRange);
  return data;
}

export async function deleteFavorites(ids: string[]) {
  await Promise.all(ids.map(id => db.delete('favorites', id)));
}
