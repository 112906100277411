<script setup lang="ts">
import { NButton } from '@likui628/naive-ui';
import {FavoriteEdit, QrScan} from '@/components';
import { DescriptionButton } from '.';
import {useFabricStore} from "@/store/fabric.ts";
import { useRouter } from 'vue-router';

const fabricStore = useFabricStore();
const router = useRouter();
const onClickTryon = async () => {
  await router.push(`/tryon`);
}

</script>

<template>
  <div class="tabbar-container">
    <QrScan>
      <template #scan-icon="slotProps">
        <NButton :loading="slotProps.loading">
          <span class="tab-item">扫码</span>
        </NButton>
      </template>
    </QrScan>
    <DescriptionButton>
      <span class="tab-item">信息</span>
    </DescriptionButton>
      <FavoriteEdit v-bind="fabricStore.fabricInfo">
        <NButton>
          <span class="tab-item">收藏</span>
        </NButton>
      </FavoriteEdit>
    <NButton>
      <span class="tab-item" @click="onClickTryon">试衣</span>
    </NButton>  
  </div>
</template>

<style scoped>
.tabbar-container {
  display: flex;
  align-items: center;
}

.tab-item {
  padding: 0 20px;
}
</style>
