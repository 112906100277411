import { ref } from 'vue';
import { isWeixinBrowser } from '@repo/utils';
import { until } from '@vueuse/core';
import { loadWeixinSdk } from './setup.ts';

interface WxScanCallback {
  onSuccess?: (...args: any[]) => void,
  onCancel?: (...args: any[]) => void,
  onFailure?: (...args: any[]) => void,
  onComplete?: (...args: any[]) => void,
}

declare global {
  interface Window {
    wx: any;
  }
}

interface WxSignature {
  'nonceStr': string;
  'timestamp': string;
  'url': string;
  'signature': string;
}

type WxSignatureApi = (url: string) => Promise<WxSignature>

function createWxScanInstance(getWxSignatureApi: WxSignatureApi) {
  const isSdkLoaded = ref<boolean>(false);

  const isReady = ref<boolean>(false);

  const initWx = async () => {
    if (isWeixinBrowser()) {
      try {
        await loadWeixinSdk();
        isSdkLoaded.value = true;
        console.log('Weixin JS SDK is ready.');
      } catch (error) {
        console.error('Failed to load Weixin JS SDK.');
      }
    } else {
      console.log('Not in Weixin browser, SDK not loaded.');
    }
  };

  return { isSdkLoaded, isReady, initWx, getWxSignatureApi };
}


let instance: ReturnType<typeof createWxScanInstance>;

function checkInstance() {
  if (!instance) {
    throw new Error('WxInstance must be initialize first');
  }
}

export async function initWxInstance(getWxSignatureApi: WxSignatureApi) {
  instance = createWxScanInstance(getWxSignatureApi);
  await instance.initWx();
}

export function useWx() {
  async function wxConfig(url: string) {
    checkInstance();

    return new Promise<void>((resolve, reject) => {
      instance.isReady.value = false;

      until(instance.isSdkLoaded).toBe(true)
        .then(() => instance.getWxSignatureApi(url))
        .then(signature => {
          window.wx.config({
            debug: false,
            appId: 'wx843585e920cdfbe9',
            timestamp: signature.timestamp,
            nonceStr: signature.nonceStr,
            signature: signature.signature,
            jsApiList: ['scanQRCode', 'updateAppMessageShareData', 'updateTimelineShareData']
          });

          window.wx.ready(function() {
            instance.isReady.value = true;
            resolve();
          });

          window.wx.error(function(res: any) {
            reject(res);
          });
        });
    });
  }

  const wxScan = async (
    {
      onSuccess,
      onComplete
    }: WxScanCallback) => {
    await until(instance.isReady).toBe(true);

    window.wx.scanQRCode({
      needResult: 1,
      scanType: ['qrCode'],
      success: function(res: any) {
        onSuccess && onSuccess(res);
      },
      complete: function() {
        onComplete && onComplete();
      }
    });
  };

  const wxUpdateAppMessageShareData = async (url: string) => {
    await until(instance.isReady).toBe(true);

    window.wx.updateAppMessageShareData({
      link: url
    });
  };

  const wxUpdateTimelineShareData = async (url: string) => {
    await until(instance.isReady).toBe(true);

    window.wx.updateTimelineShareData({
      link: url
    });
  };

  return {
    wxInstance: instance,
    wxScan,
    wxConfig,
    wxUpdateAppMessageShareData,
    wxUpdateTimelineShareData
  };
}



