// @ts-nocheck
/* eslint-disable */
import { loadImageData } from '@repo/utils';
import { cv, setup } from './setup';

function hsv255ToRgb(h: number, s: number, v: number): { r: number, g: number, b: number } {
  let R = 0, G = 0, B = 0;
  let C = 0, X = 0, Y = 0, Z = 0;
  let i = 0;
  let H = h * 2; // Equivalent to (float)(h + h)
  const S = s / 255.0;
  const V = v / 255.0;

  if (S === 0) {
    R = G = B = V;
  } else {
    H = H / 60;
    i = Math.floor(H);
    C = H - i;
    X = V * (1 - S);
    Y = V * (1 - S * C);
    Z = V * (1 - S * (1 - C));
    switch (i) {
      case 0:
        R = V;
        G = Z;
        B = X;
        break;
      case 1:
        R = Y;
        G = V;
        B = X;
        break;
      case 2:
        R = X;
        G = V;
        B = Z;
        break;
      case 3:
        R = X;
        G = Y;
        B = V;
        break;
      case 4:
        R = Z;
        G = X;
        B = V;
        break;
      case 5:
        R = V;
        G = X;
        B = Y;
        break;
    }
  }


  return { r: Math.round(R * 255), g: Math.round(G * 255), b: Math.round(B * 255) };
}

function rgbToHsv255(r: number, g: number, b: number): { h: number, s: number, v: number } {
  r = r / 255;
  g = g / 255;
  b = b / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0, s = 0, v = max;

  if (max !== 0) {
    s = 1 - (min / max);
  }

  if (max === min) {
    h = 0;
  } else if (max === r && g >= b) {
    h = 60 * ((g - b) / (max - min));
  } else if (max === r && g < b) {
    h = 60 * ((g - b) / (max - min)) + 360;
  } else if (max === g) {
    h = 60 * ((b - r) / (max - min)) + 120;
  } else if (max === b) {
    h = 60 * ((r - g) / (max - min)) + 240;
  }

  h = h * 0.5;
  v = v * 255;
  s = s * 255;

  return { h, s, v };
}

class ChangeColorItem {
  private hNew: number;
  private sNew: number;
  private vNew: number;

  constructor(
    private color: number,
    private maxV: number,
    private minV: number,
    private avgV: number,
    private stddevV: number
  ) {
    this.init();
  }

  private init() {
    const r = (this.color >> 16) & 0xFF;
    const g = (this.color >> 8) & 0xFF;
    const b = this.color & 0xFF;
    const { h, s, v } = rgbToHsv255(r, g, b);
    this.hNew = h;
    this.sNew = s;
    this.vNew = v;
  }

  h(): number {
    return this.hNew;
  }

  s(): number {
    return this.sNew;
  }

  v(): number {
    return this.vNew;
  }

  mapV(inputV: number): number {
    const dv = this.vNew - this.avgV;
    const v = inputV + dv;
    return Math.min(255, Math.max(0, Math.round(v)));
  }
}

function calculateMeanStdDev(vImage: ImageData, maskImage: ImageData): { mean: number, stddev: number } {
  let sum = 0;
  let sumSq = 0;
  let count = 0;

  for (let i = 0; i < vImage.data.length; i++) {
    if (maskImage.data[i] === 255) {
      const v = vImage.data[i];
      sum += v;
      sumSq += v * v;
      count++;
    }
  }

  const mean = sum / count;
  const variance = (sumSq / count) - (mean * mean);
  const stddev = Math.sqrt(variance);

  return { mean, stddev };
}

function calculateHistogram(vImage: ImageData, maskImage: ImageData): number[] {
  const hist = new Array(256).fill(0);
  let sumVal = 0;

  for (let i = 0; i < vImage.data.length; i++) {
    if (maskImage.data[i] === 255) {
      const v = vImage.data[i];
      hist[v]++;
      sumVal++;
    }
  }

  for (let i = 0; i < 256; i++) {
    hist[i] /= sumVal;
  }

  return hist;
}

export async function changeColor(url: string, colorHex: string) {
  if (!cv) {
    await setup();
  }

  const imageData = await loadImageData(url);
  console.log('Starting color change process...');

  // 将十六进制颜色转换为 HSV
  const [r, g, b] = hexToRgb(colorHex);
  const [h, s] = rgbToHsv(r, g, b);
  console.log(`Converted RGB to HSV: H=${h}, S=${s}`);

  const width = imageData.width;
  const height = imageData.height;

  // 将 ImageData 转换为 OpenCV Mat
  let src = cv.matFromImageData(imageData);
  let vImage = new cv.Mat(height, width, cv.CV_8UC1); // 灰度图
  let maskImage = new cv.Mat(height, width, cv.CV_8UC1); // Mask

  // 将图像转换为灰度图
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const r = imageData.data[index];
      const g = imageData.data[index + 1];
      const b = imageData.data[index + 2];
      const alpha = imageData.data[index + 3];

      if (alpha === 255) {
        const v = rgbToV255(r, g, b);
        vImage.ucharPtr(y, x)[0] = v;
        maskImage.ucharPtr(y, x)[0] = 255;
      } else {
        vImage.ucharPtr(y, x)[0] = 0;
        maskImage.ucharPtr(y, x)[0] = 0;
      }
    }
  }

  const meanStdDevResult = calculateMeanStdDev(vImage, maskImage);
  const hist = calculateHistogram(vImage, maskImage);
  const imgSt: ImageStatics = { avg: 0, min: 0, max: 0, stddev: 0, hist: new Array(256).fill(0), isAccumlate: false };
  imgSt.avg = Math.min(255, Math.max(0, meanStdDevResult.mean));
  imgSt.min = Math.min(255, Math.max(0, imgSt.avg - 3 * meanStdDevResult.stddev));
  imgSt.max = Math.min(255, Math.max(0, imgSt.avg + 3 * meanStdDevResult.stddev));
  imgSt.stddev = meanStdDevResult.stddev;
  imgSt.hist = hist;

  const cci = new ChangeColorItem(hexToInt(colorHex), imgSt.max, imgSt.min, imgSt.avg, imgSt.stddev);

  const lut: HsvLut = { h: cci.h(), s: cci.s(), v: cci.v(), vLut: new Array(256).fill(0) };
  for (let i = 0; i < 256; i++) {
    lut.vLut[i] = cci.mapV(i);
  }

  const processedData = applyColorTransform(imageData, vImage, lut);

  // 清理内存
  src.delete();
  vImage.delete();
  maskImage.delete();

  console.log('Color change process completed.');

  return new ImageData(processedData, width, height);
}

function hexToRgb(hex) {
  let bigint = parseInt(hex.substring(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
}

function hexToInt(hex) {
  return parseInt(hex.substring(1), 16);
}

function rgbToHsv(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  const delta = max - min;
  let h, s, v = max;

  s = max === 0 ? 0 : delta / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
    }
    h /= 6;
  }

  return [h * 360, s]; // 返回色调 (h) 和饱和度 (s)
}

function rgbToV255(r: number, g: number, b: number): number {
  return Math.max(r, g, b);
}

function applyColorTransform(imageData: ImageData, vImage, lut) {
  console.log('Applying color transformation...');
  const data = imageData.data;
  const width = imageData.width;
  const height = imageData.height;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const pV = vImage.ucharPtr(y, x)[0];
      const { r, g, b } = hsv255ToRgb(lut.h, lut.s, lut.vLut[pV]);

      data[index] = r;
      data[index + 1] = g;
      data[index + 2] = b;
    }
  }

  console.log('Color transformation applied.');
  return data;
}
