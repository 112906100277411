<script setup lang="ts">
import {NButton, NInput, NInputGroup, NSelect} from '@likui628/naive-ui';
import {ImageCard, ReturnButton} from './components';
import {computed, onMounted, ref} from 'vue';
import {deleteTryons, type TryOn, getTryons, searchTryons, queryTryonTask} from '@/api';
import type {TryOnItem} from './typing.ts';
import {useRouter, useRoute} from 'vue-router';
import {dateRange} from '@repo/utils';

//首先在setup中定义
const route = useRoute()

const tryons = ref<TryOnItem[]>([]);

onMounted(async () => {
  //query
  const params = history.state.params;
  if (params) {
    console.log("MyTryon[mounted]:");
    console.log("cloth", params.cloth);
    console.log("pants", params.pants);
  }

  await queryTryons();
});


const date = ref(0);
const options = ref([
  {label: '今日', value: 0},
  {label: '本周', value: 1},
  {label: '本月', value: 2},
  {label: '全部', value: -1}
]);

const range = computed<[number, number] | undefined>(() => {
  switch (date.value) {
    case 0:
      return dateRange('day');
    case 1:
      return dateRange('week');
    case 2:
      return dateRange('month');
  }
  return undefined;
});

const fabricNameMapping = (item: TryOn) => {
  const name = '' + item.id;
  return {...item, name};
};

const searchTerms = ref<string>('');
const queryTryons = async () => {
  let items: TryOn[] = [];
  if (searchTerms.value) {
    items = await searchTryons(searchTerms.value, 9999, range.value);
  } else {
    items = await getTryons(9999, range.value);
  }
  tryons.value = items.map(fabricNameMapping);
};

const onSearch = async (refreshStatus = false) => {
  await queryTryons();

  if (refreshStatus) {
    for (const tryon of tryons.value) {
        if (!!tryon.task_id) {
          try {
            const newTaskStatus = await queryTryonTask(tryon.task_id);
            if (newTaskStatus.output.task_id === tryon.task_id) {
              tryon.imageUrl = newTaskStatus.output.image_url;
              tryon.task_status = newTaskStatus.output.task_status;
            }
          } catch(ex: any)  {
            console.error("update tryon task failed", ex);
          }
        }
    }
  }
};

const onSelected = (item: TryOnItem, val: boolean) => {
  item.selected = val;
};

const onSelectAll = () => {
  tryons.value.forEach((item) => {
    item.selected = true;
  });
};

const selected = computed(() => {
  return tryons.value.filter(item => item.selected);
});

const onChange = (payload: { id: number, memo: string, num: number }) => {
  const {id, memo, num} = payload;
  const found = tryons.value.find(item => item.id === id);
  if (found) {
    found.memo = memo;
    found.num = num;
  }
};

const onDelete = async () => {
  if (selected.value.length < 1) {
    return;
  }
  const ids = selected.value.map(item => item.id);
  await deleteTryons(ids);
  await queryTryons();
};

const router = useRouter();
const onReturn = () => {
  router.back();
};
</script>

<template>
  <div class="mytryon-page">
    <div class="header">
      <ReturnButton class="return-button" @click="onReturn"/>
      <NInputGroup>
        <NSelect
            v-model:value="date"
            :options="options"
            style="width: 120px;"
            @update:value="onSearch"
        />
        <NInput type="text" v-model:value="searchTerms" placeholder="搜索(编号等)" clearable/>
        <NButton type="primary" ghost @click="onSearch(true)">刷新</NButton>
      </NInputGroup>
    </div>
    <div class="content">
      <div class="tryon-list">
        <ImageCard
            class="item"
            v-bind="item" v-for="item in tryons"
            :key="item.id"
            @selected="(val)=>onSelected(item,val)"
            @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<style>
.mytryon-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 35px;
  padding: 15px 10px 10px;
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tryon-list {
  flex: 1;
  width: 100%;
  padding: 0 10px;
  overflow-y: auto;
}

.tryon-list > .item {
  margin-bottom: 10px;
}

.toolbar {
  flex: 0 0 40px;
  padding-bottom: 10px;
}

.return-button {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
