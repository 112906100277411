<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  NModal,
  NCard,
  NButton,
  NDescriptionsItem,
  NEllipsis,
  NDescriptions
} from '@likui628/naive-ui';
import { useFabricStore } from '@/store/fabric.ts';

defineOptions({ inheritAttrs: false });

const showModal = ref(false);

const fabricStore = useFabricStore();

const property = computed(() => {
  return fabricStore.fabricProperty;
});

</script>

<template>
  <div v-bind="$attrs">
    <NButton @click="showModal=true">
      <slot></slot>
    </NButton>
  </div>
  <NModal v-model:show="showModal">
    <NCard>
      <NDescriptions label-placement="left" bordered :column="1">
        <NDescriptionsItem v-for="item of property" :key="item.name" label-style="min-width:80px;">
          <template #label>
            {{ item.name }}
          </template>
          <NEllipsis style="max-width: 140px">{{ item.value }}</NEllipsis>
        </NDescriptionsItem>
      </NDescriptions>
    </NCard>
  </NModal>
</template>

<style scoped>
</style>
