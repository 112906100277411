<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { NCarousel, NCarouselItem } from '@likui628/naive-ui';
import { getRecentProjects, type Project } from '@/api';
import { useRecentListStore } from '@/store/recent-list';
import { useParamStore } from '@/store/param.ts';

const projects = ref<Project[]>([]);
const recentListStore = useRecentListStore();

onMounted(async () => {
  projects.value = await getRecentProjects();
});

const { applyFabricToProject } = useParamStore();

const onClick = async (project: Project) => {
  await applyFabricToProject(project.scoId);
};
</script>
<template>
  <div class="share-list-container">
    <NCarousel
        slides-per-view="auto"
        :space-between="20"
        :loop="false"
        :show-dots="false"
        draggable
    >
      <NCarouselItem
        v-for="(item) in recentListStore.recentProjects"
        :key="item.id"
        class="carousel-item"
        @click="()=>onClick(item)"
      >
        <img class="carousel-img" :src="item.thumb" />
      </NCarouselItem>
    </NCarousel>
  </div>
</template>
<style scoped>
.share-list-container {
  padding: 0px 0px 0px;
}

.carousel-item {
  width: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
}

.carousel-item.active {
  transform: scale(1.2);
}

.carousel-img {
  height: 75px;
  width: 100%;
  object-fit: scale-down;
}
</style>
