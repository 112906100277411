<script setup lang="ts">

// 模特图片列表
import { ref, defineModel } from 'vue';
import { NCarousel, NCarouselItem } from '@likui628/naive-ui';
import type { UploadFileInfo } from '@likui628/naive-ui'

const model = defineModel<string>();

const selectedImageId = ref('');
const previewFileList = ref<UploadFileInfo[]>([
  {
    id: 'react',
    name: '我是react.png',
    status: 'finished',
    url: 'https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
  },
  {
    id: 'vue',
    name: '我是vue.png',
    status: 'finished',
    url: 'https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg'
  },
  {
    id: 'add-image',
    name: '我是add-image.png',
    status: 'finished',
    url: '/image/add-image.PNG'
  }
]);

const onSelectImage = async (imageFile: UploadFileInfo) => {
  if (imageFile.id == 'add-image') {
    selectedImageId.value = '';
    model.value = '';
    
    // TODO: 选择图像或者拍照
  } else {
    selectedImageId.value = imageFile.id;
    model.value = imageFile.url || '';
  }
};

</script>

<template>

<div class="share-list-container">
  <NCarousel
    slides-per-view="auto"
    :space-between="5"
    :loop="false"
    :show-dots="false"
    draggable
  >
    <NCarouselItem
      v-for="(item) in previewFileList"
      :key="item.id"
      class="carousel-item" :class="{'active': item.id == selectedImageId}"
      @click="onSelectImage(item)"
    >
      <img class="carousel-img" :src="item.url" />
    </NCarouselItem>
  </NCarousel>
</div>

</template>

<style scoped>

.share-list-container {
  padding: 0px 5px;
}

.carousel-item {
  width: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  box-sizing: border-box;
}

.carousel-item.active {
  /* transform: scale(1.2); */
  border: 1px  solid aquamarine;
}

.carousel-img {
  height: 75px;
  width: 100%;
  object-fit: scale-down;
}

</style>
