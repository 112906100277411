// @ts-nocheck
/* eslint-disable */
import { loadImageData } from '@repo/utils';
import { cv, setup } from './setup';

export async function reduceToOneColor(url: string) {
  if (!cv) {
    await setup();
  }
  console.time('reduceToOneColor');
  const imageData = await loadImageData(url);
  const width = imageData.width;
  const height = imageData.height;
  const size = width * height;

  // 直接创建输出矩阵，并获取数据指针
  const maskImage = new cv.Mat(height, width, cv.CV_8UC1);
  const mat = new cv.Mat(height, width, cv.CV_8UC3);

  const maskImageData = maskImage.data;
  const matData = mat.data;

  // 一次性处理所有像素
  for (let i = 0; i < size; i++) {
    const index = i * 4; // 对应 imageData 中的 RGBA 值
    const r = imageData.data[index];
    const g = imageData.data[index + 1];
    const b = imageData.data[index + 2];
    const alpha = imageData.data[index + 3];

    // 设置 maskImage 和 mat 数据
    maskImageData[i] = (alpha === 255) ? 255 : 0;
    matData[i * 3] = b;
    matData[i * 3 + 1] = g;
    matData[i * 3 + 2] = r;
  }

  console.timeEnd('reduceToOneColor');
  return reduceColor(mat, maskImage);
}

//参数入参范围h(0~360),s(0~255),v(0~255),这里要注意，要把s,v缩放到0~1之间
function hsv_255_to_rgb_hex(h, s, v) {
  let R = 0;
  let G = 0;
  let B = 0;
  let C = 0;
  let X = 0;
  let Y = 0;
  let Z = 0;
  let i = 0;
  let H = h;
  let S = s / 255.0;
  let V = v / 255.0;
  if (S == 0) {
    R = G = B = V;
  } else {
    H = H / 60;
    i = Math.floor(H);
    C = H - i;

    X = V * (1 - S);
    Y = V * (1 - S * C);
    Z = V * (1 - S * (1 - C));
    switch (i) {
      case 0:
        R = V;
        G = Z;
        B = X;
        break;
      case 1:
        R = Y;
        G = V;
        B = X;
        break;
      case 2:
        R = X;
        G = V;
        B = Z;
        break;
      case 3:
        R = X;
        G = Y;
        B = V;
        break;
      case 4:
        R = Z;
        G = X;
        B = V;
        break;
      case 5:
        R = V;
        G = X;
        B = Y;
        break;
    }
  }

  R = Math.round(R * 255);
  G = Math.round(G * 255);
  B = Math.round(B * 255);

  R = R.toString(16).padStart(2, '0');
  G = G.toString(16).padStart(2, '0');
  B = B.toString(16).padStart(2, '0');

  console.log(`hsv_255_to_rgb_hex #${R}${G}${B}`);
  return `#${R}${G}${B}`;
}

function reduceColor(mat, maskImage) {
  console.time('reduceColor function');

  // 转换颜色空间为 HSV
  cv.cvtColor(mat, mat, cv.COLOR_BGR2HSV);

  // 使用优化后的 _hueChannelReduce 函数
  const label = _hueChannelReduce(mat, maskImage);

  // 计算图像的 HSV 统计数据
  const kColorCount = 9;
  const imageStatics = _calculateHSVStatics(mat, label, kColorCount);

  // 查找拥有最大像素数量的颜色
  let maxPixelCount = 0;
  let maxIndex = 0;
  for (let i = 0; i < imageStatics.length; i++) {
    if (imageStatics[i].pixelCount > maxPixelCount) {
      maxPixelCount = imageStatics[i].pixelCount;
      maxIndex = i;
    }
  }

  console.timeEnd('reduceColor function');

  // 转换 HSV 值为 RGB HEX 颜色值并返回
  return hsv_255_to_rgb_hex(
    imageStatics[maxIndex].h * 2,
    imageStatics[maxIndex].s,
    imageStatics[maxIndex].v
  );
}

function _hueChannelReduce(mat, alphaMask) {
  console.time('_hueChannelReduce');
  const kMinSaturationThreshold = 40;

  // 初始化label
  let label = new cv.Mat.zeros(mat.rows, mat.cols, cv.CV_8UC1);

  const kHueChan = 0;
  const kSatChan = 1;
  const kValChan = 2;

  const matData = mat.data;
  const alphaMaskData = alphaMask.data;
  const labelData = label.data;

  for (let r = 0; r < mat.rows; ++r) {
    for (let c = 0; c < mat.cols; ++c) {
      const index = r * mat.cols + c;

      if (alphaMaskData[index] < 128) {
        labelData[index] = 8;
        continue;
      }

      const sat = matData[index * 3 + kSatChan];
      const hue = matData[index * 3 + kHueChan];
      const val = matData[index * 3 + kValChan];

      if (sat > kMinSaturationThreshold) {
        labelData[index] = ((hue + 15) % 180) / 30;
      } else {
        labelData[index] = val > 128 ? 7 : 6;
      }
    }
  }

  console.timeEnd('_hueChannelReduce');
  return label;
}

function _calculateHSVStatics(hsv, label, colorCount) {
  console.time('_calculateHSVStatics');
  const kMaxColorCount = 256;

  // 初始化数组
  let minHs = Array(kMaxColorCount).fill(256);
  let maxHs = Array(kMaxColorCount).fill(-1);
  let minSs = Array(kMaxColorCount).fill(256);
  let maxSs = Array(kMaxColorCount).fill(-1);
  let minVs = Array(kMaxColorCount).fill(256);
  let maxVs = Array(kMaxColorCount).fill(-1);
  let sumHs = Array(kMaxColorCount).fill(0.0);
  let sumSs = Array(kMaxColorCount).fill(0.0);
  let sumVs = Array(kMaxColorCount).fill(0.0);
  let pixelCountArray = Array(kMaxColorCount).fill(0);
  let redCount = Array(kMaxColorCount).fill(0);
  let notRedCount = Array(kMaxColorCount).fill(0);

  let hsvData = hsv.data;
  let labelData = label.data;

  // 先遍历计算红色计数和统计值
  for (let y = 0; y < hsv.rows; y++) {
    let rowOffset = y * hsv.cols;
    for (let x = 0; x < hsv.cols; x++) {
      let index = rowOffset + x;
      let c = labelData[index];
      let baseIdx = index * 3;
      let hue = hsvData[baseIdx];
      let sat = hsvData[baseIdx + 1];
      let val = hsvData[baseIdx + 2];

      // 计算红色和非红色计数
      if (hue <= 20 || hue >= 160) {
        redCount[c]++;
      } else {
        notRedCount[c]++;
      }

      // 校正 hue 值
      if (hue > 90 && redCount[c] > notRedCount[c]) {
        hue -= 180;
      }

      // 更新最小值和最大值
      if (hue < minHs[c]) minHs[c] = hue;
      if (hue > maxHs[c]) maxHs[c] = hue;
      if (sat < minSs[c]) minSs[c] = sat;
      if (sat > maxSs[c]) maxSs[c] = sat;
      if (val < minVs[c]) minVs[c] = val;
      if (val > maxVs[c]) maxVs[c] = val;

      // 累加和
      sumHs[c] += hue;
      sumSs[c] += sat;
      sumVs[c] += val;
      pixelCountArray[c]++;
    }
  }

  // 计算平均值
  let avgHs = sumHs.map((sum, i) => pixelCountArray[i] ? sum / pixelCountArray[i] : 0);
  let avgSs = sumSs.map((sum, i) => pixelCountArray[i] ? sum / pixelCountArray[i] : 0);
  let avgVs = sumVs.map((sum, i) => pixelCountArray[i] ? sum / pixelCountArray[i] : 0);

  // 计算标准差
  let stddevHs = Array(kMaxColorCount).fill(0);
  let stddevSs = Array(kMaxColorCount).fill(0);
  let stddevVs = Array(kMaxColorCount).fill(0);

  for (let y = 0; y < hsv.rows; y++) {
    let rowOffset = y * hsv.cols;
    for (let x = 0; x < hsv.cols; x++) {
      let index = rowOffset + x;
      let c = labelData[index];
      let baseIdx = index * 3;
      let hue = hsvData[baseIdx];
      let sat = hsvData[baseIdx + 1];
      let val = hsvData[baseIdx + 2];

      if (hue > 90 && redCount[c] > notRedCount[c]) {
        hue -= 180;
      }

      let dh = avgHs[c] - hue;
      let ds = avgSs[c] - sat;
      let dv = avgVs[c] - val;
      stddevHs[c] += dh * dh;
      stddevSs[c] += ds * ds;
      stddevVs[c] += dv * dv;
    }
  }

  stddevHs = stddevHs.map((sum, i) => pixelCountArray[i] ? Math.sqrt(sum / pixelCountArray[i]) : 0);
  stddevSs = stddevSs.map((sum, i) => pixelCountArray[i] ? Math.sqrt(sum / pixelCountArray[i]) : 0);
  stddevVs = stddevVs.map((sum, i) => pixelCountArray[i] ? Math.sqrt(sum / pixelCountArray[i]) : 0);

  // 生成图像统计信息
  let imageStatics = [];
  for (let i = 0; i < colorCount; i++) {
    if (avgHs[i] < 0) {
      avgHs[i] = 180.0 + avgHs[i];
    } else if (avgHs[i] > 180.0) {
      avgHs[i] = avgHs[i] - 180.0;
    }

    imageStatics.push({
      hStat: { min: minHs[i], max: maxHs[i], avg: avgHs[i], stddev: stddevHs[i] },
      sStat: { min: minSs[i], max: maxSs[i], avg: avgSs[i], stddev: stddevSs[i] },
      vStat: { min: minVs[i], max: maxVs[i], avg: avgVs[i], stddev: stddevVs[i] },
      h: avgHs[i],
      s: avgSs[i],
      v: avgVs[i],
      pixelCount: pixelCountArray[i]
    });
  }

  console.timeEnd('_calculateHSVStatics');
  return imageStatics;
}

