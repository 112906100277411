 <script setup lang="ts">
import { ref } from 'vue'; 
import { useRouter } from 'vue-router';
import { NCheckbox, NImage, NStatistic, useMessage } from '@likui628/naive-ui';
import { createTryonTask, saveTryon } from '@/api';
import { ReturnButton } from '../favorite/components';
import { Tabbar, ModelList } from './components';

const clothImage = ref('http://cloudknit-3d.oss-cn-shanghai.aliyuncs.com/individual_fabric_shares/default/fabric/L-1198A7BEC2884F8091340818EB7199D0_Emulation.jpg');
const pantsImage = ref('');
const modelImage = ref('');
const showPants = ref(false);
const message = useMessage();
const router = useRouter();
const onReturn = () => {
  router.back();
};

const onClickTryon = async () => {
  if (!modelImage.value) {
    message.error('请先选择模特图片');
  } else {
    console.log('modelImage=' + modelImage.value);

    const task = await createTryonTask({
      userid: "cc644597",
      param: {
        model: "aitryon",
        input: {
          top_garment_url: clothImage.value,
          bottom_garment_url: pantsImage.value,
          person_image_url: modelImage.value
        },
        parameters: {
          resolution: -1,
          restore_face: true
        }
      }
    });

    await saveTryon({
      id: 0,
      clothUrl: clothImage.value,
      modelUrl: modelImage.value,
      task_status: task.output.task_status,
      task_id: task.output.task_id,
      request_id: task.request_id,
      pantsUrl: pantsImage.value,
    });

    await router.push({
      path: '/mytryon',
      state: {
        params: {
          cloth: modelImage.value,
          pants: showPants.value ? pantsImage.value : null,
        }
      }
    });
  }
};

const onClickMyTryon = async () => {
  await router.push(`/mytryon`);
};

</script>

<template>
  <div class="tryon-page">
    <div class="header">
      <ReturnButton class="return-button" @click="onReturn"/>
    </div>

    <n-statistic label="上装和连体装" />
    
    <div class="content">
        <NImage
          :lazy="true"
          width="95%"
          :src="clothImage"
        />
    </div>

    <n-checkbox v-model:checked="showPants">下装</n-checkbox>
    <ModelList class="model-list" v-model="pantsImage" v-if="showPants" />
    
    <n-statistic label="模特图" />
    <ModelList class="model-list" v-model="modelImage"/>

    <Tabbar class="tabbar" @tryon="onClickTryon" @mytryon="onClickMyTryon"/>
  </div>
</template>

<style>

.tryon-page {
  position: relative;
  height: 100%;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 35px;
  padding: 15px 10px 10px;
  display: flex;
  align-items: center;
}

.return-button {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  flex: 1;
  width: 100%;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
}

.model-list {
  /* bottom: 60px; */
  padding: 0 10px 0;
  /* left: 0;
  right: 0; */
  display: flex;
  align-items: center;
}

.n-statistic {
  margin-left: 10px;
  margin-top: 4px;
}

.n-checkbox {
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}

</style>
