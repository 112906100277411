<script setup lang="ts">
import { NImage } from '@likui628/naive-ui';
import type { TryOnItem } from '../typing.ts';

const props = defineProps<TryOnItem>();

</script>

<template>
  <div class="image-card" v-if="props.task_status==='SUCCEEDED'">
    <!-- <span>{{ props.id }}</span> -->
    <n-image :src="props.imageUrl" width="100%" lazy object-fit="scale-down" />
  </div>
  <div class="image-card pending" v-else-if="props.task_status==='PENDING'">
    <span>正在生成，预计10-20秒，请等待</span>
  </div>
</template>

<style scoped>
.image-card {
  position: relative;
  display: flex;
  flex-direction: row;
  width: calc(100% - 40px);
  border: solid 1px #d0d0d0;
  border-radius: 7px;
  align-items: center;
  justify-content: space-around;
}

.image-card.pending {
  height: 200px;
}

</style>
