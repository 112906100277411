import { defineStore } from '@repo/stores';
import type { MetadataProperty } from '@repo/style3d';
import { computed, ref, toRaw } from 'vue';

interface FabricInfo {
  id: string;
  thumb: string;
  properties: Record<string, string>[];
}

export const useFabricStore = defineStore('fabric', () => {
  const fabricInfo = ref<FabricInfo>({ id: '', thumb: '', properties: [] });

  const fabricProperty = computed(() => {
    return fabricInfo.value?.properties || [];
  });

  const setFabricInfo = (val: FabricInfo) => {
    fabricInfo.value = val;
  };
  const setFabricProperties = (val: MetadataProperty['data']) => {
    fabricInfo.value.properties = [...val.map(toRaw)];
  };

  const setFabricThumb = (url: string) => {
    fabricInfo.value.thumb = url;
  };
  
  return { fabricInfo, fabricProperty, setFabricInfo, setFabricProperties, setFabricThumb };
});
