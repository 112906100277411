import axios from 'axios';
import type { Style3dFabric } from './typing.ts';
import { joinUrl } from '@repo/utils';
import { getAppConfig } from '@/utils';

export * from './typing.ts';

export async function getStyle3dFabricApi(fabricId: string): Promise<Style3dFabric> {
  const { URL_PARSE_URL } = getAppConfig();
  const resp = await axios.get(joinUrl(URL_PARSE_URL, `/style3d/resource/${fabricId}`));
  return resp.data.data as Style3dFabric;
}