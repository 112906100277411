<script setup lang="ts">
import { NImage, NCheckbox, NSpace, NButton } from '@likui628/naive-ui';
import type { FavoriteItem } from '../typing.ts';
import { FavoriteEdit } from '@/components';

const props = defineProps<FavoriteItem>();

const emit = defineEmits<{
  (e: 'selected', val: boolean): void,
  (e: 'change', payload: { id: string, memo: string, num: number }): void
}>();

const onClick = () => {
  emit('selected', !props.selected);
};
</script>

<template>
  <div class="fabric-card" @click="onClick">
    <NCheckbox class="check" :checked="true" v-if="props.selected" />
    <div class="image-container">
      <NImage :src="props.thumb" width="95" height="95" preview-disabled lazy object-fit="scale-down" />
    </div>
    <div class="desc">
      <div class="row">
        <div>名称</div>
        <div>{{ props.name }}</div>
      </div>
      <div class="row">
        <div>编号</div>
        <div>{{ props.id }}</div>
      </div>
      <div class="row">
        <div>备注</div>
        <div>{{ props.memo }}</div>
      </div>
      <div class="row">
        <div>数量</div>
        <NSpace justify="space-between" style="width: 100%;">
          <div>{{ props.num }}</div>
          <FavoriteEdit
            v-bind="props"
            :show-form="true"
            @click.stop=""
            @success="(val)=>emit('change',val)"
          >
            <NButton size="small">编辑</NButton>
          </FavoriteEdit>
        </NSpace>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fabric-card {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 95%;
  height: 130px;
  border: solid 1px #d0d0d0;
  border-radius: 7px;
  align-items: center;
  justify-content: space-around;
}

.check {
  position: absolute;
  right: 10px;
  top: 10px;
}

.image-container {
  flex: 0;
  padding: 5px;
  display: flex;
  align-items: center;
}

.desc {
  flex: 1;
  padding: 5px;
}

.desc .row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  font-size: 16px;
  color: #d0d0d0;
  line-height: 1.6;
}
</style>
