export let cv: any;

export async function setup() {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = '//cloudknit-3d.oss-cn-shanghai.aliyuncs.com/lib/opencv-4.10.0.js';
    script.onload = () => {
      cv = (window as any).cv;
      if (cv) {
        // @ts-ignore
        cv['onRuntimeInitialized'] = () => {
          console.log('OpenCV loaded successfully!');
          resolve();
        };
      }
    };
    script.onerror = () => {
      console.log('Failed to load OpenCV');
      reject();
    };
    document.body.appendChild(script);
  });
}
