import './assets/body.css';
import './assets/button.css';

import { bootstrap } from '@/bootstrap.ts';
import { initWxInstance, useWx } from '@repo/wx';
import { getWxSignatureApi } from '@/api/wx';
import { isIOS } from '@repo/utils';

async function initApplication() {
  await bootstrap();

  // ios 环境下wxConfig需要是第一次进入的url
  const url = location.href;

  const { wxConfig } = useWx();

  initWxInstance(getWxSignatureApi).then(() => {
    if (isIOS()) {
      wxConfig(url);
    }
  });
}

initApplication();
