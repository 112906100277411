<script setup lang="ts">
import {
  darkTheme,
  NConfigProvider,
  NMessageProvider,
  zhCN,
  NDialogProvider,
  NModalProvider
} from '@likui628/naive-ui';
import { setWindowTitle, themeOverrides } from '@repo/utils';
import { getAppConfig } from '@/utils';
import { LoadingProvider } from '@repo/components';

const { DEFAULT_COMPANY_NAME } = getAppConfig();
setWindowTitle(DEFAULT_COMPANY_NAME);
</script>

<template>
  <NConfigProvider :locale="zhCN" :theme="darkTheme" :theme-overrides="themeOverrides">
    <NModalProvider>
      <NDialogProvider>
        <LoadingProvider>
          <NMessageProvider :max="1">
            <RouterView />
          </NMessageProvider>
        </LoadingProvider>
      </NDialogProvider>
    </NModalProvider>
  </NConfigProvider>
</template>
<style scoped>
</style>
