<script setup lang="ts">
import {onMounted, ref, toRaw} from 'vue';
import {NButton, NModal, NForm, NFormItem, NInput} from '@likui628/naive-ui';
import type {FavoriteItem} from '../typing.ts';
import {exportImage} from '../export-image.ts';
import {useLoading} from '@repo/components';

const props = withDefaults(
    defineProps<{
      favoriteList: FavoriteItem[],
      selected: FavoriteItem[]
    }>(),
    {
      favoriteList: () => [],
      selected: () => []
    });

const emit = defineEmits<{
  (e: 'selectAll'): void
  (e: 'delete'): void
}>();

const showModal = ref<boolean>(false);
const step = ref<1 | 2>(1);
const imageUrl = ref('');

const loading = useLoading();
const onExportImage = async () => {
  showModal.value = true;
  step.value = 1;
};

const handleImgPreview = async () => {
  localStorage.setItem("export-image-form", JSON.stringify(formModel.value));
  loading.setLoading(true);
  const list = (props.selected.length > 0)
      ? props.selected
      : props.favoriteList
  imageUrl.value = await exportImage(toRaw(formModel.value), list);
  step.value = 2;
  loading.setLoading(false);
}
const formModel = ref({
  company: '',
  contact: '',
  phone: '',
  address: '',
  memo: ''
})

onMounted(() => {
  const val = localStorage.getItem('export-image-form')
  if (val) {
    formModel.value = JSON.parse(val)
  }
})
</script>

<template>
  <div class="toolbar-container">
    <NButton @click="onExportImage">导出图片</NButton>
    <NButton @click="emit('selectAll')">全选</NButton>
    <NButton
        @click="emit('delete')"
        :disabled="!(props.selected.length>0)"
    >
      删除
    </NButton>
    <NModal v-model:show="showModal" preset="card">
      <div>
        <NForm
            v-if="step===1"
            :model="formModel"
            label-placement="left"
            label-width="80"
            label-align="left"
            size="small"
        >
          <NFormItem label="公司" path="company">
            <NInput v-model:value="formModel.company"/>
          </NFormItem>
          <NFormItem label="联系人" path="company">
            <NInput v-model:value="formModel.contact"/>
          </NFormItem>
          <NFormItem label="电话" path="company">
            <NInput v-model:value="formModel.phone"/>
          </NFormItem>
          <NFormItem label="地址" path="company">
            <NInput type="textarea" v-model:value="formModel.address"/>
          </NFormItem>
          <NFormItem label="备注" path="company">
            <NInput type="textarea" v-model:value="formModel.memo"/>
          </NFormItem>
          <div style="display: flex; justify-content: flex-end; column-gap: 10px;">
            <NButton type="primary" size="small" @click="handleImgPreview">
              确定
            </NButton>
            <NButton size="small" @click="showModal=false">
              取消
            </NButton>
          </div>
        </NForm>
        <img v-if="step===2" :src="imageUrl" alt="" class="img-preview">
      </div>
    </NModal>
  </div>
</template>

<style scoped>
.toolbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.img-preview {
  padding: 10px;
  width: 90%;
}
</style>
