import { joinUrl, setWindowTitle } from '@repo/utils';
import { getAppConfig } from '@/utils';
import { computed, watch } from 'vue';
import { useRelationStore } from '@repo/stores';
import { defineStore } from '@repo/stores';
import { useParamStore } from '@/store/param.ts';

const { OSS_BASE_URL, INDIVIDUAL_SHARES, DEFAULT_COMPANY_NAME } = getAppConfig();
const baseUrl = `${OSS_BASE_URL}${INDIVIDUAL_SHARES}`;

export const useShareStore = defineStore('share', () => {
  const paramStore = useParamStore();
  const relationStore = useRelationStore();

  const logoUrl = computed(() => relationStore.relation?.metadata?.logo && joinUrl(baseUrl, paramStore.scoId, relationStore.relation?.metadata.logo));

  watch(() => relationStore.relation?.companyName, (val) => {
    setWindowTitle(val || DEFAULT_COMPANY_NAME);
  });

  return {
    logoUrl
  };
});
