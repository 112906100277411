<script setup lang="ts">
import { Logo, Style3dViewer } from '@repo/components';
import { useShareStore } from '@/store/share.ts';
import { storeToRefs } from '@repo/stores';
import { Tabbar, RecentList } from './components';
import { SvgShoppingCartIcon } from '@repo/icons';
import {useRouter} from "vue-router";

const shareStore = useShareStore();
const { logoUrl } = storeToRefs(shareStore);

const router = useRouter();
const toFavorite = () => {
  router.push('/favorite');
};
</script>

<template>
  <Logo class="logo" :logo-url="logoUrl" />
  <div class="favorite-icon" @click="toFavorite">
    <SvgShoppingCartIcon class="icon" />
  </div>
  <div class="style3d">
    <Style3dViewer />
  </div>
  <RecentList class="recent-list" />
  <Tabbar class="tabbar" />
</template>

<style>
.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.favorite-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.favorite-icon .icon {
  width: 30px;
  height: 30px;
  color: #ffffff99;
}

.tabbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  justify-content: space-around;
  font-size: 1.2em;
  padding-bottom: 10px;
}

.style3d {
  height: calc(100% - 140px);
}

.recent-list {
  position: absolute;
  bottom: 40px;
  padding: 0 10px 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
}
</style>
