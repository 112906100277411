import { getAppConfig } from '@/utils/env';
import { defineStore, storeToRefs } from '@repo/stores';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { until } from '@vueuse/core';
import { type FabricShareInfo, type Relation, useStyle3d, mergeShareFabricInfo } from '@repo/style3d';
import axios, { type AxiosResponse } from 'axios';
import { joinUrl } from '@repo/utils';
import { getStyle3dFabricApi, saveRecentProject } from '@/api';
import { changeColor, reduceToOneColor } from '@repo/opencv';
import { useLoading } from '@repo/components';
import { useStyle3dStore, useRelationStore } from '@repo/stores';
import { useRecentListStore } from '@/store/recent-list.ts';
import { useFabricStore } from '@/store/fabric.ts';

const { OSS_BASE_URL, INDIVIDUAL_SHARES, INDIVIDUAL_FABRIC_SHARES } = getAppConfig();
const projectRootPath = `${OSS_BASE_URL}${INDIVIDUAL_SHARES}`;
const fabricRootPath = `${OSS_BASE_URL}${INDIVIDUAL_FABRIC_SHARES}`;

const need3DResourceRoutes = ['project', 'fabric', 'style3d'];

async function generateRibImage(mainColor: string): Promise<string> {
  return new Promise(resolve => {
    changeColor('//cloudknit-3d.oss-cn-shanghai.aliyuncs.com/resource/Rib/1X1.png', mainColor)
      .then(changedImageData => {
        const canvas = document.createElement('canvas');
        canvas.width = changedImageData.width;
        canvas.height = changedImageData.height;
        const ctx = canvas.getContext('2d');
        ctx!.putImageData(changedImageData, 0, 0);

        const dataUri = canvas.toDataURL('image/png');
        resolve(dataUri);
      });
  });
}

export const useParamStore = defineStore('param', () => {
  const route = useRoute();
  const loadingSpin = useLoading();

  const rootPath = computed(() => {
    return joinUrl(projectRootPath, scoId.value);
  });

  const scoId = ref<string>('');
  const fabricId = ref<string | null>(null);
  const style3dFabricId = ref<string | null>(null);

  const { loadSco, loadFabric, resetCameraViewState, getScreenshot } = useStyle3d();

  const style3dStore = useStyle3dStore();
  const { loaded: sdkLoaded, ready: sdkReady } = storeToRefs(style3dStore);

  const relationStore = useRelationStore();

  async function loadScoResource() {
    console.time('loadScoResource');
    await until(sdkLoaded).toBe(true);

    await loadSco(joinUrl(rootPath.value, '/sco/sco.json'));
    console.timeEnd('loadScoResource');
  }

  const fabricStore = useFabricStore();

  async function loadFabricResource() {
    console.time('loadFabricResource');
    await until(sdkReady).toBe(true);

    const jsonResult: AxiosResponse<Relation> = await axios.get(joinUrl(rootPath.value, '/relation.json'));
    const json = jsonResult.data;
    let shapeList = json.shape;
    let fabricRoot = joinUrl(projectRootPath, scoId.value);

    if (fabricId.value) {
      fabricRoot = joinUrl(fabricRootPath, fabricId.value);
      const infoResult: AxiosResponse<FabricShareInfo> = await axios.get(joinUrl(fabricRoot, 'info.json'));
      const info = infoResult.data;

      fabricStore.setFabricInfo({
        id: fabricId.value,
        thumb: joinUrl(fabricRoot, 'fabric', info.info.main.name),
        properties: info.metaData.property
      });

      shapeList = mergeShareFabricInfo(json.shape, info);
    }

    relationStore.setRelation({ ...json, shape: shapeList });
    await loadFabric(shapeList, fabricRoot);
    await resetCameraViewState();
    console.timeEnd('loadFabricResource');
  }

  async function loadStyle3dResource() {
    if (!style3dFabricId.value) {
      console.error('Failed to load fabric resource');
      return;
    }

    const jsonResult: AxiosResponse<Relation> = await axios.get(joinUrl(rootPath.value, '/relation.json'));
    const json = jsonResult.data;
    let shapeList = json.shape;
    const style3dFabric = await getStyle3dFabricApi(style3dFabricId.value);
    await until(sdkReady).toBe(true);
    const path = style3dFabric.data.xhr_path || style3dFabric.data.thumb_path;

    const keyMap = style3dFabric.create_info.fields.map(item => ({ name: item.name, key: item.key }));
    const fabricProperty = keyMap
      .map((item) => ({ name: item.name, key: item.key, value: style3dFabric.data[item.key] }))
      .filter(item => item.value);

    fabricStore.setFabricInfo({
      id: `style3d-${style3dFabricId.value}`,
      thumb: path,
      properties: fabricProperty
    });

    // style3d sdk默认会加t=js2019，这里加上可以利用缓存
    const name = `${path}?t=js2019`;
    const mainColor = await reduceToOneColor(name);
    const newUrl = await generateRibImage(mainColor);
    const ribInfo = {
      'height': 99,
      'name': newUrl,
      'width': 110
    };

    const { width, height } = style3dFabric.data;
    const fabricInfo: Omit<FabricShareInfo, 'mask' | 'metaData'> = {
      'info': {
        'hem': {
          ...ribInfo
        },
        'collar': {
          ...ribInfo
        },
        'main': {
          name,
          width,
          height,
          // 尺寸单位为mm，无需进行转换
          dpm: undefined
        }
      }
    };

    shapeList = mergeShareFabricInfo(shapeList, fabricInfo);

    relationStore.setRelation({ ...json, shape: shapeList });
    await loadFabric(shapeList, '');
    await resetCameraViewState();
  }

  async function reload() {
    loadingSpin.setLoading(true);
    await loadScoResource();
    if (route.name === 'style3d') {
      await loadStyle3dResource();
    } else {
      await loadFabricResource();
    }
    loadingSpin.setLoading(false);
  }

  watch(() => route.name, async () => {
    if (need3DResourceRoutes.includes(route.name as string)) {
      await refreshLoadResourceByRoute();
    }
  }, {
    immediate: true
  });

  async function refreshLoadResourceByRoute() {
    const lastScoId = route.query.scoId as string | undefined;

    if (route.name === 'fabric') {
      scoId.value = lastScoId || 'fabric_hanger';
      fabricId.value = route.params.id as string;
      style3dFabricId.value = null;
    } else if (route.name === 'style3d') {
      scoId.value = lastScoId || 'fabric_hanger';
      fabricId.value = null;
      style3dFabricId.value = route.params.id as string;
    } else {
      scoId.value = route.params.id as string;
      fabricId.value = null;
    }

    await reload();
  }

  const recentListStore = useRecentListStore();

  async function addToRecentList() {
    const thumb = await getScreenshot();
    if (fabricId.value) {
      await saveRecentProject({
        id: scoId.value,
        scoId: scoId.value,
        fabricId: fabricId.value,
        style3dFabricId: '',
        type: 'normal',
        thumb
      });
    } else if (style3dFabricId.value) {
      await saveRecentProject({
        id: scoId.value,
        scoId: scoId.value,
        fabricId: '',
        style3dFabricId: style3dFabricId.value,
        type: 'style3d',
        thumb
      });
    }
    recentListStore.recentUpdate = Date.now();
  }

  async function applyFabricToProject(id: string) {
    scoId.value = id;

    await reload().then(addToRecentList);
  }

  const router = useRouter();
  watch([() => route.name as string, () => scoId.value],
    async ([name, scoId]) => {
      await router.replace({
        name: name,
        query: {
          scoId: scoId
        }
      });
    });

  return { scoId, rootPath, applyFabricToProject, refreshLoadResourceByRoute };
});
