import axios from 'axios';
import { getAppConfig } from '@/utils';
import { joinUrl } from '@repo/utils';

interface TryonRequest {
    userid: string;
    param: {
        model: string;
        input: {
            top_garment_url: string;
            bottom_garment_url?: string;
            person_image_url: string;
        };
        parameters: {
            resolution: number;
            restore_face: boolean;
        };
    }
}

interface TryonTaskResponse {
    output: {
        task_id: string;
        task_status: string;
    };
    request_id: string;
}

interface TryonStatusResponse {
    output: {
        task_id: string;
        task_status: string;
        submit_time: string;
        scheduled_time: string;
        end_time: string;
        image_url?: string;
    };
    usage: {
        image_count: number;
    };
    request_id: string;
}

export async function createTryonTask(reqParam: TryonRequest): Promise<TryonTaskResponse> {
    const { URL_PARSE_URL } = getAppConfig();

    const response = await axios.post(joinUrl(URL_PARSE_URL, `/tryon/`), reqParam);
    if (response.status !== 200) {
        throw new Error(
            `create tryon task fail : ${response.status} ${response.statusText}`
        );
    }
    const taskResponse = response.data;

    if (!taskResponse) {
        throw new Error('Failed to create tryon task');
    }

    return taskResponse;
}

export async function queryTryonTask(taskid: string): Promise<TryonStatusResponse> {
    const { URL_PARSE_URL } = getAppConfig();

    const response = await axios.get(joinUrl(URL_PARSE_URL, `/tryon/task/`, taskid));
    if (response.status !== 200) {
        throw new Error(
            `create tryon task fail : ${response.status} ${response.statusText}`
        );
    }
    const taskResponse = response.data;

    if (!taskResponse) {
        throw new Error('Failed to create tryon task');
    }

    return taskResponse;
}


