import { defineStore } from '@repo/stores';
import { ref, watch } from 'vue';
import { getRecentProjects, type Project } from '@/api';

export const useRecentListStore = defineStore('recent-list', () => {
  const recentUpdate = ref<number>();
  const recentProjects = ref<Project[]>([]);

  watch(() => recentUpdate.value, async () => {
    recentProjects.value = await getRecentProjects(30);
  }, {
    immediate: true
  });

  return { recentUpdate, recentProjects };
});
